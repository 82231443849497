import React from "react"
import { Link } from "gatsby"
import { serializers } from "../js/blockContentSerializer"
const BlockContent = require("@sanity/block-content-to-react")

const contentSection = ({ title, text, linkText, linkUrl }) => (
  <div className="content-section">
    <div className="content-section__content">
      <div className={"content-section__text-content"}>
        {title ? <h2 className="content-section__title">{title}</h2> : null}
        {text ? (
          <BlockContent
            blocks={text}
            projectId="6t60r0yd"
            dataset="production"
            serializers={serializers}
          />
        ) : null}

        {linkUrl ? (
          <Link className="content-section__link" to={linkUrl}>
            {linkText ? linkText : "Link"}
          </Link>
        ) : null}
      </div>
    </div>
  </div>
)

export default contentSection
