import React from "react"
import Layout from "../components/layout"
import ContentSection from "../components/contentSection"
import { graphql } from "gatsby"

class AboutMe extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      contentPanels: null,
    }
    this.renderContentPanels = this.renderContentPanels.bind(this)
  }
  render() {
    let contentPanels = this.state.contentPanels
    return (
      <Layout>
        <div className="wrapper wrapper--tablet-width">
          <div className="margin-wrapper">{contentPanels}</div>
        </div>
      </Layout>
    )
  }

  componentDidMount() {
    this.renderContentPanels()
  }

  renderContentPanels() {
    const contentPanelsData = this.props.data.allSanityInfoPanel.nodes
    contentPanelsData.sort((a, b) => {
      return a.severity - b.severity
    })
    let contentPanels = contentPanelsData.map(panel => (
      <ContentSection
        key={panel.title}
        title={panel.title}
        image={false}
        text={panel._rawText}
        linkText={panel.linkText}
        linkUrl={panel.linkUrl}
      />
    ))
    this.setState({ contentPanels })
  }
}

export default AboutMe

export const query = graphql`
  query {
    allSanityInfoPanel(filter: { location: { eq: "aboutMe" } }) {
      nodes {
        title
        image {
          asset {
            url
          }
        }
        linkUrl
        linkText
        severity
        _rawText(resolveReferences: { maxDepth: 2 })
      }
    }
  }
`
